import { useCmsBlock } from '@bluheadless/ui-logic/src/hooks/cms/useCmsBlock'
import { Block } from '@bluheadless/ui/src/particles/cms-block'
import { cx } from '@emotion/css'
import { array, node, object, string } from 'prop-types'
import BottomArea from './bottom-area'
import { Wrapper } from './footer.styled'
import MiddleBottomArea from './middle-bottom-area'
import MiddleTopArea from './middle-top-area'
import TopArea from './top-area'

const FOOTER_NEWSLETTER_ID = 'footer-top'

const Footer = ({ className, topAreaChildComponent, newsletterProps, copyrightProps, menus = [] }) => {
	const { content } = useCmsBlock({ identifier: FOOTER_NEWSLETTER_ID })

	return (
		<Wrapper className={cx('Footer-root', className)}>
			<TopArea childComponent={topAreaChildComponent ?? (!!content && <Block content={content} />)} />
			<MiddleTopArea menus={menus} newsletterProps={newsletterProps} />
			<MiddleBottomArea />
			<BottomArea copyrightProps={copyrightProps} />
		</Wrapper>
	)
}

Footer.propTypes = {
	/**
	 * Defines a classname for root node
	 */
	className: string,
	/**
	 * Defines topArea Content
	 */
	topAreaChildComponent: node,
	/**
	 * Defines newsletter properties
	 */
	newsletterProps: object,
	/**
	 * Defines the properties of copyright text
	 */
	copyrightProps: object,
	/**
	 * Defines the footer menus array
	 */
	menus: array,
}

export default Footer
