import { styled } from '@mui/system'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Link from '../../molecules/link'
import Accordion from '../../molecules/accordion'
import Typography from '../../atoms/typography'

const Wrapper = styled('div')`
	flex-grow: 1;
	order: 2;
	border: none;
	${({ theme }) => theme.breakpoints.up('lg')} {
		display: flex;
		order: 1;
	}
	& .MuiPaper-root {
		background: none;
		border: none;
		${({ theme }) => theme.breakpoints.up('lg')} {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			border: none;
		}
		& .MuiAccordionSummary-root {
			min-height: auto;
			padding: var(--spacing-2) 0;
			&:not(.Mui-expanded) {
				border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
			}
			${({ theme }) => theme.breakpoints.up('lg')} {
				padding: 0;
				margin-bottom: 24px;
				border-bottom: none;
			}
		}
		& .MuiAccordionSummary-expandIconWrapper {
			color: currentColor;
			${({ theme }) => theme.breakpoints.up('lg')} {
				display: none;
			}
		}
		& .MuiAccordionDetails-root {
			padding: 0;
		}
	}
`

const ListStyled = styled(List)`
	display: flex;
	flex-direction: column;
	gap: var(--spacing-2);
`

const ListItemStyled = styled(ListItem)`
	flex-direction: column;
	align-items: flex-start;
	.MuiButton-root {
		justify-content: flex-start;
	}
`

const NavLink = styled(Link)`
	color: var(--color-primary);
	padding-bottom: 16px;
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	${({ theme }) => theme.breakpoints.up('lg')} {
		margin-bottom: 4px;
		padding-bottom: 0;
	}
`

const AccordionStyled = styled(Accordion)`
	${({ theme }) => theme.breakpoints.up('lg')} {
		& .MuiCollapse-root {
			transition-duration: 0ms !important;
		}
		& .MuiAccordionSummary-content {
			cursor: text;
		}
	}
`

const TypographyStyled = styled(Typography)`
	color: currentColor;
	display: flex;
	flex-grow: 1;
	padding: 22px;
	${({ theme }) => theme.breakpoints.up('lg')} {
		padding: 0;
	}
`

export { Wrapper, ListStyled, ListItemStyled, NavLink, AccordionStyled, TypographyStyled }
