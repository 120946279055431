import { cx } from '@emotion/css'
import { ChangeCountryLanguageStyled, MiddleBottomWrapper } from './middle-bottom-area.styled'

const MiddleBottomArea = (className) => {
	return (
		<MiddleBottomWrapper className={cx('MiddleBottomArea-root', className)}>
			<ChangeCountryLanguageStyled
				className="change-country-language-trigger-footer"
				showFlag={false}
				showCurrencySymbol={false}
			/>
		</MiddleBottomWrapper>
	)
}

export default MiddleBottomArea
