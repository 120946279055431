import { styled } from '@mui/system'

const BottomAreaWrapper = styled('div')`
	min-height: 32px;
	background-color: ${({ theme }) => theme.palette.footer.bgBottom};
	display: flex;
	align-items: center;
	border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
	justify-content: center;
	text-align: center;
	padding: 0 25px;
	a {
		color: ${({ theme }) => theme.palette.footer.color};
	}
	${({ theme }) => theme.breakpoints.up('lg')} {
		padding: calc(var(--spacing) * 1.5) var(--spacing-2);
		justify-content: flex-start;
	}
`

export { BottomAreaWrapper }
