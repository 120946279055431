import SocialLinks from '@bluheadless/ui/src/organisms/footer/social-links'
import { cx } from '@emotion/css'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/system'
import { array, object } from 'prop-types'
import Menus from '../menus'
import { MiddleTopWrapper } from './middle-top-area.styled'

const MiddleTopArea = ({ menus, className }) => {
	const theme = useTheme()
	const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

	return menus.length > 0 ? (
		<MiddleTopWrapper className={cx('MiddleTopArea-root', className)}>
			{!lgUp && (
				<SocialLinks
					showTitle={false}
					className="BHFooterSocialLinks"
					buttonProps={{ variant: 'icon' }}
					iconProps={{ fontSize: 'middle' }}
				/>
			)}
			{menus.length > 0 && <Menus className="BHFooterMenus" menus={menus} />}
		</MiddleTopWrapper>
	) : null
}

MiddleTopArea.propTypes = {
	/**
	 * Defines the footer menus array
	 */
	menus: array,
	/**
	 * Defines newsletter properties
	 */
	newsletterProps: object,
}

export default MiddleTopArea
