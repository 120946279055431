import { styled } from '@mui/system'

const MiddleTopWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleTop};

	display: flex;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('lg')} {
		flex-direction: row;
		padding: calc(var(--spacing) * 5.75) var(--spacing-2);
	}
	& .BHFooterMenus {
		padding: var(--spacing-4) var(--spacing-2) var(--spacing-2);
		${({ theme }) => theme.breakpoints.up('lg')} {
			border-top: none;
			padding: 0;
		}
		& .BhFooterMenuTitle {
			color: ${({ theme }) => theme.palette.footer.color};
		}
		& .MuiPaper-root {
			border: none;
			& .MuiAccordionSummary-content {
				color: ${({ theme }) => theme.palette.footer.color};
				.MuiTypography-root {
					${({ theme }) => theme.typography.body3};
				}
			}
		}
		.MuiAccordion-root {
			margin-bottom: 0;
		}
	}
	& .BHNewsletter {
		padding: 30px 25px 0;
		${({ theme }) => theme.breakpoints.up('xsm')} {
			padding: 30px 45px 0;
		}
		${({ theme }) => theme.breakpoints.up('lg')} {
			padding: 0;
		}
	}

	& .BHSocialWrapper {
		text-align: center;
		padding: var(--spacing-3) var(--spacing-2) var(--spacing-4);
		& a {
			color: ${({ theme }) => theme.palette.footer.color};
		}
	}

	${({ theme }) => theme.breakpoints.up('lg')} {
		& .BHFooterMenus {
			& .MuiPaper-root {
				& .MuiAccordionSummary-content {
					.MuiTypography-root {
						font-size: ${({ theme }) => theme.typography.pxToRem(14)};
					}
				}
			}
		}
	}
`

const NewsletterWrapper = styled('div')`
	max-width: 100%;
	order: 1;
	${({ theme }) => theme.breakpoints.up('lg')} {
		order: 2;
		max-width: 255px;
	}
`

export { MiddleTopWrapper, NewsletterWrapper }
